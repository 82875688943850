import React from 'react';
import {Row, Container} from "react-bootstrap";
import ProductVimeoPlayer from "./ProductVimeoPlayer";

class ProductVimeo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            'paused': false,
            'error': false,
            'vimeoId': null,
            'videoId': null,
            'current': 0,
            'tmpTime': 0,
            'videoCount': 0,
            'firstElement': 0,
            'timeWatch': 0,
        };
    }

    componentDidMount() {
        this.loadVideo();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const {profileVideo} = this.props.children;
        if (profileVideo.vimeoId !== nextProps.children.profileVideo.vimeoId
            || this.state.current !== nextState.current || nextProps.chapter !== this.props.chapter
            || profileVideo.watchedTime !== nextProps.children.profileVideo.watchedTime
        ) {
            return true;
        }
        return false;
    }

    componentDidUpdate(prevProps, prevState) {
        const {profileVideo} = this.props.children;

        if (profileVideo.vimeoId !== prevProps.children.profileVideo.vimeoId) {
            this.setState({
                current: profileVideo.sort,
                timeWatch: 0
            });
        }
    }

    loadVideo() {
        const {product, auth} = this.props.children;

        if (product.videos && product.videos[0]) {
            this.setState({
                firstElement: product.videos[0].sort,
                videoCount: product.videos[0].sort === 0 ? product.videos.length - 1 : product.videos.length
            })
        }

        let tmp = product.videos.find((item) => item.paid !== true);

        if ((!auth.isAuthenticated || auth.decreaseLevel) && tmp && tmp.vimeoId !== 0) {
            this.props.setVideo(tmp);
            this.setState({current: tmp.sort})
        } else if (auth.isAuthenticated) {
            if (product.videos.length !== 0) {
                tmp = product.videos[0];
                this.props.setVideo(tmp);
                this.setState({current: tmp.sort})
            }
        }
    }

    videoNav(sort) {
        const {product} = this.props.children;
        let tmp = product.videos.find((item) => item.sort === sort);
        if (tmp && tmp.vimeoId) {
            this.props.setVideo(tmp);
        }
    }

    handlePlayerPause() {
        this.setState({paused: true});
    }

    handlePlayerPlay() {
        this.setState({paused: false});
    }

    handleTimeUpdate = (event) => {
        const {auth} = this.props.children;
        if (auth.isAuthenticated && !auth.decreaseLevel) {
            let timeNow = parseInt(new Date().getTime() / 1000);
            if ((timeNow - this.state.tmpTime) >= 10) {
                this.saveWatchTime(event)
                this.setState({tmpTime: timeNow});
            }
        }
    }

    saveWatchTime(event) {
        const {product, profileVideo, profileVideoSavedWatch} = this.props.children;
        let seconds = parseInt(event.seconds);

        if (profileVideo.videoId && seconds >= 2) {
            let method = 'PUT';
            if (product && !profileVideo.watchedTime && seconds && this.state.timeWatch === 0) {
                method = 'POST';
                this.setState({timeWatch: seconds})
            }
            profileVideoSavedWatch(product.id, profileVideo.vimeoId, profileVideo.videoId, seconds, profileVideo.sort, method);
        }
    }

    handleVideoEnd() {
        const {product, auth} = this.props.children;
        if (auth.isAuthenticated && !auth.decreaseLevel) {
            if (product.videos.length > this.state.current) {
                this.videoNav(this.state.current + 1);
            }
        }
    }
    handleOnSeeked () {

    }
    render() {
        const {auth, product, profileVideo, history} = this.props.children;

        if (!profileVideo.vimeoId)
            return (
                <div className={"video-warning"}>
                    <img src={"/video-warning.png"} alt={"Video warning"}/>
                </div>
            );

        if (history.location.search === '?debug=1') {
            console.log('profileVideo', profileVideo)
            console.log('product', product)
            console.log('state', this.state)
        }

        return (
            <>
                <ProductVimeoPlayer
                    className="video-box"
                    width={this.props.width}
                    start={profileVideo.watchedTime}
                    video={profileVideo.vimeoId}
                    onPause={this.handlePlayerPause.bind(this)}
                    onPlay={this.handlePlayerPlay.bind(this)}
                    onTimeUpdate={this.handleTimeUpdate}
                    onEnd={this.handleVideoEnd.bind(this)}
                    onSeeked = {this.handleOnSeeked.bind(this)}
                    chapter = {this.props.chapter}
                />
                {
                    auth.isAuthenticated && !auth.decreaseLevel && product.videos.length > 1
                        ? <Container>
                            <Row className="vimeo-navigation-controls">
                                <div className={"float-left"}>
                                    <button
                                        className={`btn ${this.state.firstElement === this.state.current ? 'disabled' : ''}`}
                                        onClick={this.videoNav.bind(this, this.state.current - 1)}
                                        disabled={this.state.firstElement === this.state.current ?? true}
                                    > Poprzedni wykład
                                    </button>
                                </div>
                                <div className={"float-right"}>
                                    <button
                                        className={`btn ${this.state.videoCount === this.state.current ? 'disabled' : ''}`}
                                        onClick={this.videoNav.bind(this, this.state.current + 1)}
                                        disabled={this.state.videoCount === this.state.current ?? true}
                                    >Następny wykład
                                    </button>
                                </div>
                            </Row>
                        </Container>
                        : <Container className={"vimeo-nav-not-logged"}></Container>
                }
            </>
        );
    }
}

export default ProductVimeo;
import React from 'react';
import {
    productDocumentFetch,
    productFetch,
    productListQuerySlider,
    productTypesFetch,
    productUnload,
} from "../../actions/product";
import {connect} from "react-redux";
import {Button, Col, Container, Row, Spinner} from "react-bootstrap";
import ProductVimeo from "./video/ProductVimeo";
import {deviceType} from "react-device-detect";
import {categoryAncestorsFetch, categoryProductsListFetch} from "../../actions/category";
import {
    profileDeleteProduct,
    profileSavedProduct,
    profileSavedProductsFetch,
    profileVideoSavedWatch,
    profileVideoSetTimeWatched,
    profileVideoWatchFetch
} from "../../actions/profile";
import {iriFormatId} from "../../common/apiUtils";
import {Helmet} from "react-helmet";
import {convertMetaDescription, convertMetaTitle, parseTrainingTime, strNormalize} from "../../common/utils";
import ProductVideoList from "./video/ProductVideoList";
import {registerOfferListFetch} from "../../actions/register";
import ProductContent from "./ProductContent";
import RightSection from "./RightSection";
import ProductOffer from "./ProductOffer";
import ExpertContainer from "../Modules/Experts/Sliders/ExpertSliderContainer";
import AwardsSlider from "../Modules/AwardsBanner/AwardsSlider";
import WebinarFree from "./webinar/webinarFree";
import WebinarPaid from "./webinar/webinarPaid";
import striptags from "striptags";
import Breadcrumb from "../../common/breadcrumb";
import ProductOnlineContent from "./ProductOnlineContent";
import PromotionContainer from "../Modules/PromotionBanner/PromotionContainer";
import ListItems from "../Modules/Index/Lists/ListItems";
import {APP_URL} from "../../constants";


const mapStateToProps = state => ({
    ...state.product,
    ...state.auth,
    auth: state.auth,
    category_ancestors: state.category.category_ancestors,
    category_products: state.category.products,
    profileVideo: state.profileVideo,
    productTypes: state.product.types,
    offers: state.register.offers,
    document: state.product.document
});

const mapDispatchToProps = {
    productFetch,
    productTypesFetch,
    productUnload,
    categoryAncestorsFetch,
    categoryProductsListFetch,
    profileSavedProductsFetch,
    profileSavedProduct,
    profileDeleteProduct,
    profileVideoWatchFetch,
    profileVideoSavedWatch,
    registerOfferListFetch,
    profileVideoSetTimeWatched,
    productDocumentFetch,
    productListQuerySlider,
};

class Product extends React.Component {

    constructor(props) {
        super(props);
        this.setDeviceType();
        this.state = {
            filterCategory: {category: []},
            show: false,
            typeFilter: {
                type: ['webinar']
            },
            chapter: 0
        }
    }

    setDeviceType() {
        switch (deviceType) {
            case 'browser':
                this.width = '730px';
                break;
            case 'tablet':
                this.width = '690px';
                break;
            case 'phone':
                this.width = '340px';
                break;
            default:
        }
    }

    componentDidMount() {
        const {auth, productFetch, match, product, isFetching} = this.props;
        const urlToken = window.location.search.slice(1);
        this.props.productTypesFetch();
        if (auth.isAuthenticated) {
            productFetch(match.params.id, true, urlToken);
        } else {
            productFetch(match.params.id, false, urlToken);
        }
        if (product && !isFetching) {

        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {auth, profileVideo, profileVideoWatchFetch, match, error, isFetching, product} = this.props;
        const isFallbackUser = this.hasFallbackUserRole();

        if (error) {
            if (!isFetching) {
                this.props.history.push(`/404`);
            } else {
                setInterval(() => {
                    this.props.history.push(`/404`);
                }, 15000); //lagi...
            }
        }

        if (auth.isAuthenticated && !isFallbackUser && profileVideo.vimeoId !== prevProps.profileVideo.vimeoId) {
            profileVideoWatchFetch(match.params.id, profileVideo.videoId);
        }
        if (prevProps.product !== product) {
            this.setState({
                    breadcrumbItems: [
                        {name: 'szkolenia'},
                        {name: product.name}
                    ]
                }
            )

        }
        if (product && this.state.filterCategory.category.length === 0) {
            this.state.filterCategory.category.push(iriFormatId(product.categories[0]))
            this.props.productListQuerySlider(this.state.typeFilter, this.state.filterCategory, 3, 'nearest')

        }
    }

    componentWillUnmount() {
        this.props.productUnload();
    }


    hasFallbackUserRole = () => {
        const { product, auth } = this.props;
        const fallbackRole = "ROLE_FALLBACK_USER";
        if (!product || !auth) {
            return false
        }
        if (!auth.isAuthenticated) {
            return false
        }
        return product.categories.some(category => {
            const matchingBrand = auth.userData.brands.find(brand => brand.brandName === category.name);
            return matchingBrand && matchingBrand.role === fallbackRole;
        });
    }
    getTypeName(types, products) {
        return types.filter((item) => item.type === products.subType).pop().name;
    }

    setVideo(item) {
        this.props.profileVideoSetTimeWatched(
            item.vimeoId,
            item.id,
            item.watchedTime,
            item.sort
        );
    }

    getImg() {
        const {product, auth} = this.props;

        return (
            <> {auth.isAuthenticated ?
                <>
                    <div className={"webinar-image-badge"}>
                        <div className={"webinar-image-badge-type"}>Szkolenie Online</div>
                        <div
                            className={`webinar-image-badge-category badge-${strNormalize(product.categories[0].name)}`}>{product.categories[0].name}</div>
                    </div>
                    <div className={"opacity-background"} style={{width: `100%`}}>
                    </div>
                    <h3>{product.accessDeniedMessage} </h3>
                    {product.imageUrl
                        ? <img src={product.imageUrl} alt={product.name} style={{maxWidth: `100%`, width: `100%`}}/>
                        : <img src={"/zaslepka.png"} alt={"webinar"}/>}
                </> :
                <>{product.imageUrl
                    ? <img src={product.imageUrl} alt={product.name} style={{maxWidth: `100%`, width: `100%`}}/>
                    : <img src={"/zaslepka.png"} alt={"webinar"}/>}</>}
            </>
        )
    }


    getProductList() {
        const {auth, product, profileVideo, chapters} = this.props;
        const isFallbackUser = this.hasFallbackUserRole();

        if ((auth.isAuthenticated && !isFallbackUser) || product.accessDenied === false) {
            if (product.videos.length > 1) {
                return (
                    <ProductVideoList
                        product={product}
                        auth={auth}
                        setVideo={this.setVideo.bind(this)}
                        profileVideo={profileVideo}
                    />);
            } else {
                if (chapters) {
                    return (
                        <div className="video-list">
                            <h3>Program szkolenia</h3>
                            <p>Kliknij na rozdział, aby odtworzyć</p>
                            <ul className="btn-video-list">
                                {chapters.map((item, key) => {
                                    const eventClick = () => {
                                        //videoSetChapter(item.startTime)
                                        this.setState({chapter: item.startTime})
                                    };
                                    return (
                                        <>
                                            {((auth.isAuthenticated && !isFallbackUser) || item.paid !== true) ?
                                                <li>
                                                    <button key={key} data-time={item.startTime}
                                                            className={'video-list-item'}
                                                            onClick={eventClick}><span>{item.title}</span></button>
                                                </li>
                                                :
                                                <p>{item.title}</p>
                                            }</>
                                    )
                                })}
                            </ul>

                        </div>
                    )
                }
            }

        } else {
            return <ProductOffer children={this.props}/>
        }
    }

    render() {
        const isFallbackUser = this.hasFallbackUserRole();

        const {product, isFetching, auth, role, productQuerySList} = this.props;
        let firstVideoFree = false;
        if (isFetching || !product) {
            return (
                <Container className="home-main-container spinner-container">
                    <Row>
                        <Container className="home-products-container">
                            <br/><br/><Spinner animation="grow"/>
                        </Container>
                    </Row>
                </Container>
            );
        }
        if (!isFetching && product && product.subType === 'webinar') {
            return (
                <>
                    <WebinarFree
                        children={this.props}
                        getTypeName={this.getTypeName}
                    />
                </>

            )
        } else if (!isFetching && product && product.subType === 'webinarPaid') {
            return (
                <>
                    <WebinarPaid
                        children={this.props}
                        getTypeName={this.getTypeName}
                    />
                </>

            )
        } else {
            if (product && product.videos && product.videos.length && product.videos[0].paid === false) {
                firstVideoFree = true;
            }
            return (
                <>
                    <Helmet>
                        <title>{'Szkolenie ' + convertMetaTitle(product.name)}</title>
                        <meta name="description"
                              content={convertMetaDescription(product.metaDescription ? product.metaDescription : product.name)}/>
                        <link rel="canonical"
                              href={`/${strNormalize(product.subType)}/${strNormalize(product.name)}/${iriFormatId(product)}`}/>
                    </Helmet>
                    {isFallbackUser ?
                        <Container className={'mt-3'}>
                            <div className="alert alert-danger expired-access-box">Twój dostęp wygasł! <a
                                href={product.categories[0].name === "Oświata" ? "/oswiata/abonament" : "/ksiegowosc-i-kadry/abonament"}>Odnów</a> go lub zadzwoń 22
                                518 29 29
                            </div>
                        </Container>
                        : ''}

                    {(auth && auth.isAuthenticated) || (!product.accessDenied && firstVideoFree) ?
                        <Container fluid className={"product-container-wrapper"}>
                            <Row>
                                <Container fluid
                                           className={`product-container-bg ${strNormalize(product.categories[0].name)}`}>
                                    <Container className={"product-data"}>
                                        <Row>
                                            <Container>
                                                <Breadcrumb id={product.categories[0].name === "Oświata" ? 71 : 13}
                                                            items={this.state.breadcrumbItems}/>
                                            </Container>
                                            <Col lg={4} className={'webinar-data'}><h1>{product.name}</h1>
                                                <h2>Ekspert:
                                                    {product.experts ?
                                                        <>{product && product.experts.map((expert, key) => (
                                                            <span key={key}>&nbsp;
                                                                {expert.name} {expert.surname}{product.experts.length > key + 1 && product.experts.length > 1 ? ', ' : ''}
                                            </span>
                                                        ))}
                                                        </>
                                                        : ''
                                                    }
                                                </h2>
                                                {product.experts[0].shortDescription ?
                                                    <h2>{striptags(product.experts[0].shortDescription)}</h2> : ''}
                                                <h2
                                                    className={"webinar-duration white"}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                                         viewBox="0 0 25 25" fill="none">
                                                        <path
                                                            d="M12.4997 22.9163C18.2526 22.9163 22.9163 18.2526 22.9163 12.4997C22.9163 6.74671 18.2526 2.08301 12.4997 2.08301C6.74671 2.08301 2.08301 6.74671 2.08301 12.4997C2.08301 18.2526 6.74671 22.9163 12.4997 22.9163Z"
                                                            stroke={product.categories[0].name === "Oświata" ? '#4AA5D8' : '#FDD26E'}
                                                            strokeWidth="1.5"/>
                                                        <path d="M12.5 8.33301V12.4997L14.5833 14.583"
                                                              stroke={product.categories[0].name === "Oświata" ? '#4AA5D8' : '#FDD26E'}
                                                              strokeWidth="1.5" strokeLinecap="round"
                                                              strokeLinejoin="round"/>
                                                    </svg>
                                                    {parseTrainingTime(product.videosLength)}</h2>
                                                {product.archive ?
                                                    <h2 className={"is-archive"}>Archiwalne</h2> :
                                                    <h2 className={"is-archive active"}>Aktualne</h2>
                                                }
                                                <div className={"product-online-btn-container"}>
                                                    <Button href={"#sheets"}
                                                            className={"educado-btn educado-btn-white"}>Sprawdź
                                                        materiały</Button>
                                                    {product.quiz && auth.isAuthenticated && product.certificatesUsers && role !== "ROLE_TEST_USER" ?
                                                        <Button
                                                            href={product.certificatesUsers.length > 0 ? (APP_URL + product.certificatesUsers[0]) : "#quiz"}
                                                            className={"educado-btn educado-btn-white"}>Pobierz
                                                            certyfikat</Button> : ''}
                                                </div>

                                            </Col>
                                            <Col lg={8} className={"webinar-image"}>
                                                <div
                                                    className={(product.accessDenied && auth.isAuthenticated) || !auth.isAuthenticated ? "product-image-denied product-image-wrapper" : " product-image-wrapper prodcut-image-access"}>
                                                    {/*{(auth.isAuthenticated && !isFallbackUser) || (firstVideoFree && !product.accessDenied) ? '' :*/}
                                                    {/*    <div className={"webinar-image-badge"}>*/}
                                                    {/*        <div className={"webinar-image-badge-type"}>szkolenie</div>*/}
                                                    {/*        <div*/}
                                                    {/*            className={`webinar-image-badge-category badge-${strNormalize(product.categories[0].name)}`}>{product.categories[0].name}</div>*/}
                                                    {/*    </div>*/}
                                                    {/*}*/}
                                                    {product && ((auth.isAuthenticated && !isFallbackUser) || firstVideoFree) && !product.accessDenied ?
                                                        <ProductVimeo
                                                            width={this.width}
                                                            children={this.props}
                                                            setVideo={this.setVideo.bind(this)}
                                                            chapter={this.state.chapter}

                                                        />
                                                        : this.getImg()
                                                    }</div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Container>
                            </Row>
                            <Container>
                                <Row>
                                    <Col
                                        className={`webinar-details-container ${strNormalize(product.categories[0].name)}`}
                                        lg={8} md={6}>
                                        <Container className={"product-details-container"}>
                                            <ProductOnlineContent children={this.props}/>

                                            {(auth.isAuthenticated && !isFallbackUser) || !product.accessDenied ?
                                                '' :
                                                <a href={product.categories[0].name === "Oświata" ? "/oswiata/abonament" : "/ksiegowosc-i-kadry/abonament"}
                                                        className={"mt-5 educado-btn educado-btn-black btn btn-primary"}>Kup
                                                    teraz</a>}
                                        </Container>
                                    </Col>
                                    <Col className={"webinar-form-container mt-4"} lg={4} md={6}>
                                        {this.getProductList()}
                                        <RightSection product={product}/>
                                        <Button className={"scroll-top"} onClick={() => {
                                            window.scrollTo({
                                                top: 0,
                                                behavior: 'smooth'
                                            })
                                        }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-chevron-up"
                                                 viewBox="0 0 16 16">
                                                <path fillRule="evenodd"
                                                      d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"/>
                                            </svg>
                                        </Button>
                                    </Col>
                                    <Container>
                                        <h3 className={"headline-small text-center"}>podobne szkolenia</h3>
                                        <Row>
                                            <ListItems products={productQuerySList && productQuerySList}
                                                       isFetching={isFetching} auth={auth}
                                                       id={iriFormatId(product.categories[0])}/>
                                        </Row>
                                    </Container>
                                </Row>
                            </Container>
                        </Container>
                        :
                        <Container fluid className={"product-container-wrapper position-relative"}>
                            <Row>
                                <div className={'position-absolute index-background'}>
                                    {product.categories[0].name === "Oświata" ?
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1920" height="914"
                                             viewBox="0 0 1920 914"
                                             fill="none">
                                            <path
                                                d="M1920 0V419.456C1881.25 431.105 1833.35 445.923 1777.76 463.86C1620.19 514.842 1542.94 351.105 1352.76 341.904C1162.57 332.703 1007.35 487.405 961.209 604.26C915.064 721.114 672.956 957.633 434.91 906.258C208.375 857.373 76.6032 772.54 0 847.48V0H1920Z"
                                                fill="url(#paint0_linear_825_39506)"/>
                                            <defs>
                                                <linearGradient id="paint0_linear_825_39506" x1="909.426"
                                                                y1="235.819"
                                                                x2="981.055"
                                                                y2="837.995" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#242936"/>
                                                    <stop offset="1" stopColor="#32509F"/>
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                        :
                                        <svg xmlns="http://www.w3.org/2000/svg" width="1920" height="914"
                                             viewBox="0 0 1920 914"
                                             fill="none">
                                            <path
                                                d="M1920 0.25V419.706C1881.25 431.355 1833.35 446.173 1777.76 464.11C1620.19 515.092 1542.94 351.355 1352.76 342.154C1162.57 332.953 1007.35 487.655 961.209 604.51C915.064 721.364 672.956 957.883 434.91 906.508C208.375 857.623 76.6032 772.79 0 847.73V0.25H1920Z"
                                                fill="#232936"/>
                                            <path
                                                d="M1920 0.25V419.706C1881.25 431.355 1833.35 446.173 1777.76 464.11C1620.19 515.092 1542.94 351.355 1352.76 342.154C1162.57 332.953 1007.35 487.655 961.209 604.51C915.064 721.364 672.956 957.883 434.91 906.508C208.375 857.623 76.6032 772.79 0 847.73V0.25H1920Z"
                                                fill="url(#paint0_linear_1301_18274)"/>
                                            <defs>
                                                <linearGradient id="paint0_linear_1301_18274" x1="403.394"
                                                                y1="21.146"
                                                                x2="564.366" y2="1037.59"
                                                                gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#232936"/>
                                                    <stop offset="0.197335" stopColor="#232936" stopOpacity="0.8"/>
                                                    <stop offset="0.365393" stopColor="#232936" stopOpacity="0.3"/>
                                                    <stop offset="0.449096" stopColor="#232936" stopOpacity="0.15"/>
                                                    <stop offset="0.650905" stopColor="#FAB14C" stopOpacity="0.55"/>
                                                    <stop offset="0.764992" stopColor="#FAA13F" stopOpacity="0.9"/>
                                                    <stop offset="0.854733" stopColor="#FAA13F"/>
                                                    <stop offset="1" stopColor="#FAA13F"/>
                                                </linearGradient>
                                            </defs>
                                        </svg>}
                                </div>
                            </Row>
                            <Container className={"product-online-content"}>
                                <Row>
                                    <Container>
                                        <Breadcrumb id={product.categories[0].name === "Oświata" ? 71 : 14}
                                                    items={this.state.breadcrumbItems}/>
                                    </Container>
                                    <Col lg={8} className={'product-data'}>
                                        <Container>
                                            <h1>{product.name}</h1>
                                            <h2>Ekspert:
                                                {product.experts ?
                                                    <>{product && product.experts.map((expert, key) => (
                                                        <span key={key}>&nbsp;
                                                            {expert.name} {expert.surname}{product.experts.length > key + 1 && product.experts.length > 1 ? ', ' : ''}
                                            </span>
                                                    ))}
                                                    </>
                                                    : ''
                                                }
                                            </h2>
                                            {product.experts ? <h2
                                                dangerouslySetInnerHTML={{__html: product.experts[0].shortDescription}}/> : ''}
                                            <h2
                                                className={"webinar-duration white"}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                                     viewBox="0 0 25 25" fill="none">
                                                    <path
                                                        d="M12.4997 22.9163C18.2526 22.9163 22.9163 18.2526 22.9163 12.4997C22.9163 6.74671 18.2526 2.08301 12.4997 2.08301C6.74671 2.08301 2.08301 6.74671 2.08301 12.4997C2.08301 18.2526 6.74671 22.9163 12.4997 22.9163Z"
                                                        stroke={product.categories[0].name === "Oświata" ? '#4AA5D8' : '#FDD26E'}
                                                        strokeWidth="1.5"/>
                                                    <path d="M12.5 8.33301V12.4997L14.5833 14.583"
                                                          stroke={product.categories[0].name === "Oświata" ? '#4AA5D8' : '#FDD26E'}
                                                          strokeWidth="1.5" strokeLinecap="round"
                                                          strokeLinejoin="round"/>
                                                </svg>
                                                {parseTrainingTime(product.videosLength)}</h2>
                                        </Container>
                                        <Container className={"product-details-container"}>
                                            <ProductOnlineContent children={this.props}/>
                                        </Container>
                                    </Col>
                                    <Col lg={4} className={"product-online-right not-logged"}>
                                        <div
                                            className={'product-online-right-content'}>
                                            <div className={"img-container"}>
                                                {this.getImg()}
                                                <div className={'button-wrapper'}>
                                                    <Button  href={product.categories[0].name === "Oświata" ? "/oswiata/abonament" : "/ksiegowosc-i-kadry/abonament"} title={"oferta"}
                                                            className={'educado-btn educado-btn-full'}>Kup
                                                        w abonamencie</Button></div>
                                            </div>
                                            <div className={'benefit-container'}>
                                                <ul className={"product-benefits-container mt-5"}>
                                                    <li>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25"
                                                             height="25" viewBox="0 0 25 25"
                                                             fill="none">
                                                            <path
                                                                d="M12.4997 22.9163C18.2526 22.9163 22.9163 18.2526 22.9163 12.4997C22.9163 6.74671 18.2526 2.08301 12.4997 2.08301C6.74671 2.08301 2.08301 6.74671 2.08301 12.4997C2.08301 18.2526 6.74671 22.9163 12.4997 22.9163Z"
                                                                stroke="#232936" strokeWidth="1.5"/>
                                                            <path d="M12.5 8.33301V12.4997L14.5833 14.583"
                                                                  stroke="#232936" strokeWidth="1.5"
                                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                        <p>Ponad 40-minutowe szkolenia specjalistyczne</p></li>
                                                    <li>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25"
                                                             height="25" viewBox="0 0 25 25"
                                                             fill="none">
                                                            <path
                                                                d="M19.2705 18.5809C20.3439 18.4233 21.1067 18.1185 21.6959 17.5293C22.9163 16.3089 22.9163 14.3447 22.9163 10.4163C22.9163 6.48797 22.9163 4.52379 21.6959 3.30339C20.4756 2.08301 18.5113 2.08301 14.583 2.08301H10.4163C6.48797 2.08301 4.52379 2.08301 3.30339 3.30339C2.08301 4.52379 2.08301 6.48797 2.08301 10.4163C2.08301 14.3447 2.08301 16.3089 3.30339 17.5293C4.14525 18.3711 5.34103 18.6323 7.29134 18.7132"
                                                                stroke="#232936" strokeWidth="1.5"
                                                                strokeLinecap="round"/>
                                                            <path d="M17.7087 7.29199H7.29199" stroke="#232936"
                                                                  strokeWidth="1.5"
                                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path
                                                                d="M15.1038 15.1042C15.1038 16.5424 13.9379 17.7083 12.4997 17.7083C11.0614 17.7083 9.89551 16.5424 9.89551 15.1042C9.89551 13.6659 11.0614 12.5 12.4997 12.5C13.9379 12.5 15.1038 13.6659 15.1038 15.1042Z"
                                                                stroke="#232936" strokeWidth="1.5"/>
                                                            <path
                                                                d="M9.89551 15.1045C9.89551 19.3398 11.6895 21.7405 12.4997 22.917L14.0622 19.792L15.8851 20.8337L17.708 21.8753C16.9427 21.1345 16.1515 18.7994 16.1515 18.7994"
                                                                stroke="#232936" strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"/>
                                                        </svg>
                                                        <p>Imienny certyfikat</p></li>
                                                    <li>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25"
                                                             height="25" viewBox="0 0 25 25"
                                                             fill="none">
                                                            <path
                                                                d="M21.3538 17.6336V10.4163C21.3538 6.48797 21.3538 4.52379 20.1334 3.30339C18.9131 2.08301 16.9488 2.08301 13.0205 2.08301H11.9788C8.05047 2.08301 6.08629 2.08301 4.86589 3.30339C3.64551 4.52379 3.64551 6.48797 3.64551 10.4163V20.3122"
                                                                stroke="#141B34" strokeWidth="1.5"
                                                                strokeLinecap="round"/>
                                                            <path
                                                                d="M21.3538 17.708H6.24967C4.81143 17.708 3.64551 18.8739 3.64551 20.3122C3.64551 21.7504 4.81143 22.9163 6.24967 22.9163H21.3538"
                                                                stroke="#141B34" strokeWidth="1.5"
                                                                strokeLinecap="round"/>
                                                            <path
                                                                d="M21.3542 22.9163C19.9159 22.9163 18.75 21.7504 18.75 20.3122C18.75 18.8739 19.9159 17.708 21.3542 17.708"
                                                                stroke="#141B34" strokeWidth="1.5"
                                                                strokeLinecap="round"/>
                                                            <path d="M15.625 7.29199H9.375" stroke="#141B34"
                                                                  strokeWidth="1.5"
                                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path d="M12.5 11.458H9.375" stroke="#141B34"
                                                                  strokeWidth="1.5"
                                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                        <p>Dodatkowe materiały szkoleniowe</p></li>
                                                    <li>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25"
                                                             height="25" viewBox="0 0 25 25"
                                                             fill="none">
                                                            <path
                                                                d="M7.29199 18.7503V16.1462M7.29199 16.1462V14.5837C7.29199 14.0926 7.29199 13.8471 7.45217 13.6945C7.61235 13.542 7.87015 13.542 8.38574 13.542H9.11491C9.86998 13.542 10.4821 14.1249 10.4821 14.8441C10.4821 15.5632 9.86998 16.1462 9.11491 16.1462H7.29199ZM21.8753 13.542H20.5081C19.6488 13.542 19.2192 13.542 18.9522 13.7963C18.6852 14.0505 18.6852 14.4597 18.6852 15.2781V16.1462M18.6852 16.1462V18.7503M18.6852 16.1462H20.9639M16.4066 16.1462C16.4066 17.5844 15.1823 18.7503 13.6722 18.7503C13.3315 18.7503 13.1611 18.7503 13.0342 18.6805C12.7303 18.5135 12.7607 18.1753 12.7607 17.8823V14.41C12.7607 14.117 12.7303 13.7789 13.0342 13.6118C13.1611 13.542 13.3315 13.542 13.6722 13.542C15.1823 13.542 16.4066 14.7079 16.4066 16.1462Z"
                                                                stroke="#232936" strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"/>
                                                            <path
                                                                d="M15.625 22.9163H11.1743C7.77716 22.9163 6.0786 22.9163 4.89903 22.0853C4.56106 21.8472 4.26102 21.5648 4.00802 21.2467C3.125 20.1364 3.125 18.5378 3.125 15.3406V12.689C3.125 9.60243 3.125 8.05911 3.61348 6.8265C4.39876 4.8449 6.05952 3.28184 8.16496 2.54275C9.47461 2.08301 11.1144 2.08301 14.394 2.08301C16.268 2.08301 17.205 2.08301 17.9533 2.34572C19.1565 2.76806 20.1054 3.66124 20.5542 4.79357C20.8333 5.49792 20.8333 6.37982 20.8333 8.14361V10.4163"
                                                                stroke="#232936" strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"/>
                                                            <path
                                                                d="M3.125 12.4997C3.125 10.5821 4.67956 9.02746 6.59722 9.02746C7.29075 9.02746 8.10837 9.14898 8.78268 8.9683C9.38179 8.80776 9.84975 8.3398 10.0103 7.74067C10.191 7.06638 10.0694 6.24876 10.0694 5.55523C10.0694 3.63757 11.6241 2.08301 13.5417 2.08301"
                                                                stroke="#232936" strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"/>
                                                        </svg>
                                                        <p>Prezentacje PDF ze szkolenia</p></li>

                                                </ul>
                                            </div>
                                            <div className={'offer-container'}>
                                                <h2>Chcesz kupić dostęp dla 10 lub większej liczby osób?</h2>
                                                <div className={"multi"}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="62" height="62"
                                                         viewBox="0 0 62 62" fill="none">
                                                        <path
                                                            d="M53.6662 46.5C55.6019 46.5 57.1415 45.282 58.5241 43.5785C61.3539 40.0918 56.7075 37.3054 54.9356 35.9409C53.134 34.5536 51.1226 33.7678 49.0833 33.5833M46.5 28.4167C50.0668 28.4167 52.9583 25.5252 52.9583 21.9583C52.9583 18.3915 50.0668 15.5 46.5 15.5"
                                                            stroke="#4AA5D8" strokeWidth="2"
                                                            strokeLinecap="round"/>
                                                        <path
                                                            d="M8.3334 46.5C6.39771 46.5 4.85807 45.282 3.47567 43.5785C0.645732 40.0918 5.29207 37.3054 7.06418 35.9409C8.86559 34.5536 10.877 33.7678 12.9163 33.5833M14.208 28.4167C10.6412 28.4167 7.74967 25.5252 7.74967 21.9583C7.74967 18.3915 10.6412 15.5 14.208 15.5"
                                                            stroke="#4AA5D8" strokeWidth="2"
                                                            strokeLinecap="round"/>
                                                        <path
                                                            d="M20.8828 39.0376C18.2432 40.6697 11.3224 44.0025 15.5376 48.1728C17.5968 50.21 19.8901 51.667 22.7734 51.667H39.226C42.1093 51.667 44.4025 50.21 46.4617 48.1728C50.6769 44.0025 43.7562 40.6697 41.1165 39.0376C34.9266 35.2101 27.0727 35.2101 20.8828 39.0376Z"
                                                            stroke="#4AA5D8" strokeWidth="2" strokeLinecap="round"
                                                            strokeLinejoin="round"/>
                                                        <path
                                                            d="M40.0413 19.3747C40.0413 24.3683 35.9933 28.4163 30.9997 28.4163C26.0061 28.4163 21.958 24.3683 21.958 19.3747C21.958 14.3811 26.0061 10.333 30.9997 10.333C35.9933 10.333 40.0413 14.3811 40.0413 19.3747Z"
                                                            stroke="#4AA5D8" strokeWidth="2"/>
                                                    </svg>
                                                    <p>Uzyskaj dostęp dla wszystkich współpracowników ze swojej
                                                        organizacji w najbardziej korzystnej cenie
                                                    </p>
                                                </div>
                                                <div className={'button-wrapper'}>
                                                    <Button href={product.categories[0].name === "Oświata" ? '/oswiata/plan-dla-szkoly' :'/ksiegowosc-i-kadry/plan-dla-biura'}
                                                        className={"educado-btn educado-btn-black educado-btn-full"}>Poznaj
                                                        ofertę</Button>
                                                </div>


                                            </div>
                                        </div>
                                        <RightSection product={product}/>
                                        <Button className={"scroll-top"} onClick={() => {
                                            window.scrollTo({
                                                top: 0,
                                                behavior: 'smooth'
                                            })
                                        }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-chevron-up"
                                                 viewBox="0 0 16 16">
                                                <path fillRule="evenodd"
                                                      d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"/>
                                            </svg>
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        </Container>
                    }
                    {product.ads && product.ads.map((item, index) => {
                        if (item.zone === "leaderboard") {
                            return (
                                <Container key={index} className={"text-center mb-5"}>
                                    <div className={"product-banner banner-front"}>
                                        {item.url ? <a target={'_blank'} href={item.url} rel='noreferrer'>
                                            <img alt={item.name} src={item.imageUrl}/>
                                        </a> : ''}

                                        <Helmet>
                                            <script>{item.js}</script>
                                        </Helmet>
                                    </div>
                                </Container>
                            )
                        }
                        return '';
                    })}
                    {auth && auth.isAuthenticated ? '' :
                        <PromotionContainer id={product.categories[0].name === "Oświata" ? 71 : 13}/>}
                    <ExpertContainer/>
                    <AwardsSlider/>
                </>
            );
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Product);